import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const menuWrap = {
  'line-height': '1.4em',
  'padding-top': '1.5em',
  'padding-bottom': '1.5em',
}

const hidden = {
  display: 'none',
}

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/cosmetology">
            Cosmetology
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/cosmetician">
            Cosmetician
          </Link>
        </li>
        <li>
          <Link
            onClick={props.onToggleMenu}
            to="/cosmetology-instructor"
            style={menuWrap}
          >
            Cosmetology Instructor Trainee
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/manicuring">
            Manicuring
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/enrollment">
            How to Enroll
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/guest-services">
            Guest Services
          </Link>
        </li>
      </ul>
      <ul className="actions vertical">
        <li>
          <a
            href="#contact"
            className="button special fit"
            onClick={props.onToggleMenu}
          >
            Contact
          </a>
        </li>
        <li>
          <Link to="/school-catalog" className="button fit hidden">
            School Catalog (2024)
          </Link>
        </li>
      </ul>
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/school-disclosures">
            School Disclosures
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/net-price-calculator">
            Net Price Calculator
          </Link>
        </li>
      </ul>
    </div>
    {
      // eslint-disable-next-line
    }
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
